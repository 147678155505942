import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import GatsbyImage from "gatsby-image";
import { Layout, SEO } from "../../components/structure";
import {
  Content,
  Hero,
  CallToAction,
  Button,
  PlatformRevenueContainer,
  IconBlock,
} from "../../components/blocks";

import styled from "styled-components";
import { mq, font, brand } from "../../styles";

import english from "../../../content/translations/en-CA/about.json";
import french from "../../../content/translations/fr-CA/about.json";

const IndexPage = () => {
  let lang = english;
  const data = useStaticQuery(graphql`
    query {
      allHands: file(base: { eq: "aboutAllHands.jpg" }) {
        childImageSharp {
          fixed(width: 500, height: 400, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      mobileallHands: file(base: { eq: "aboutAllHands.jpg" }) {
        childImageSharp {
          fixed(width: 1000, height: 300, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      hospitalityIcon: file(base: { eq: "icon-Hospitality.png" }) {
        childImageSharp {
          fixed(width: 100, height: 107, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      PlusgradeTimeline2: file(base: { eq: "PlusgradeTimeline2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1450, maxHeight: 350, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      heroImg: file(base: { eq: "Plusgrade-Hero-Banner-About.jpg" }) {
        ...FullHeroBackground
      }
      rightArrowIcon: file(base: { eq: "arrow-right-blue.png" }) {
        ...ButtonIcon
      }
      whiterightArrowIcon: file(base: { eq: "arrow-right.png" }) {
        ...ButtonIcon
      }
      aboutUsBackground: file(base: { eq: "GettyImages-1044786902.jpg" }) {
        childImageSharp {
          fixed(width: 1100, height: 700, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      pointsLogo: file(base: { eq: "Points.png" }) {
        childImageSharp {
          fixed(width: 145, height: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      upstayLogo: file(base: { eq: "Upstay.png" }) {
        childImageSharp {
          fixed(width: 185, height: 85, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);
  const PlatformRevenueContainer = styled.div`
    ${font.imports.sherika}
    width: 100%;
    height: auto;
    min-height: 550px;
    margin: auto;
    position: relative;
    @media screen and ${mq.maxSm} {
      min-height: 700px;
    }
    @media screen and (max-width: 700px) {
      min-height: 720px;
    }
    @media screen and (max-width: 630px) {
      min-height: 765px;
    }
    @media screen and (max-width: 500px) {
      min-height: 800px;
    }
    @media screen and (max-width: 450px) {
      min-height: 765px;
    }
    @media screen and (max-width: 415px) {
      min-height: 790px;
    }
    @media screen and (max-width: 381px) {
      min-height: 800px;
    }
    @media screen and (max-width: 350px) {
      min-height: 825px;
    }
    @media screen and (max-width: 345px) {
      min-height: 835px;
    }
    @media screen and (max-width: 330px) {
      min-height: 855px;
    }
    .background-con {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      .aboutBg.gatsby-image-wrapper {
        width: 100% !important;
        height: 100% !important;
        position: absolute !important;
        filter: grayscale(1) contrast(1.2);
      }
      &:after {
        content: "";
        position: absolute;
        z-index: 350;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          0deg,
          rgba(0, 4, 20, 0.65),
          rgba(0, 4, 20, 0.65)
        );
      }
    }

    .content-con {
      position: relative;
      z-index: 400;
      width: 95%;
      max-width: 1100px;
      margin: auto;
      height: 100%;
      padding: 50px 0;
      color: #fff;
      @media screen and ${mq.maxMd} {
        padding: 50px 0;
        text-align: center;
        width: 90%;
      }
      @media screen and ${mq.maxSm} {
        padding: 40px 0 20px;
      }
      h3 {
        font-size: 36px;
        color: #fff;
        font-weight: 600;
        text-shadow: 0 0 40px ${brand.colors.pg_primary_dark};
        @media screen and ${mq.maxSm} {
          font-size: 30px;
        }
      }

      .icon-content-con {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        position: relative;
        top: 30px;
        margin-bottom: 100px;
        @media screen and ${mq.maxSm} {
          display: block;
          top: 0px;
          .points {
            span {
              width: 100% !important;
              a {
                font-size: 16px;
              }
            }
          }
          .upstay {
            margin-top: 50px;
            .desktop-break {
              display: none;
            }
            span {
              width: 100% !important;
              a {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  `;
  return (
    <Layout>
      <SEO
        title={"About Us | Plusgrade"}
        description={
          "We are Plusgrade, the Global Ancillary Revenue Powerhouse."
        }
        keywords={"travel, ancillary revenue, about us, "}
      />
      <Hero
        title={"About"}
        subtitle={lang.hero.subtitle}
        image={data["heroImg"]}
        fullBG
      />
      <Content bgDarkBlue>
        <h3>We are the global ancillary revenue powerhouse</h3>
        <p>
          Plusgrade brings happiness to travelers while driving ancillary
          revenue for the global travel industry. We partner with 200+ airline,
          hospitality, cruise, passenger rail, and financial services companies
          to create new, meaningful revenue streams through incredible customer
          experiences. Founded in 2009, we are headquartered in Montreal with
          offices in Toronto, New York, London, Dubai, Tel Aviv and Singapore.
        </p>
      </Content>
      <PlatformRevenueContainer>
        <div className="background-con">
          <GatsbyImage
            className={"aboutBg"}
            fixed={data["aboutUsBackground"].childImageSharp.fixed}
            imgStyle={{ objectFit: "cover", objectPosition: "center" }}
          />

          <div className="content-con">
            <h3>Growth & expansion</h3>
            <div className="icon-content-con">
              <IconBlock
                icon={data["pointsLogo"]}
                aboutPage
                className={"points"}
                copy={"Points, a Plusgrade Company"}
              >
                <div style={{ marginBottom: "24px" }}>
                  In 2022, Plusgrade acquired Points, the leader in loyalty
                  commerce, bringing two of the largest sources of ancillary
                  revenue under one roof to create an even greater impact for
                  travel businesses worldwide.
                </div>
                <Button
                  destination={"https://www.points.com"}
                  label={"Learn more about Points"}
                  icon={data["whiterightArrowIcon"]}
                  rel={"noreferer"}
                  target={"_blank"}
                  orange
                  pill
                  whiteText
                  external
                />
              </IconBlock>
              <IconBlock
                icon={data["hospitalityIcon"]}
                aboutPage
                className={"upstay"}
                copy={"UpStay, now Plusgrade Hospitality"}
              >
                <div style={{ marginBottom: "24px" }}>
                  UpStay is now part of Plusgrade's hospitality business,
                  enhancing our solutions to help hotels maximize revenue and
                  deliver exceptional guest experiences.
                </div>
                <br className="desktop-break" />
                <Button
                  destination={
                    "https://www.plusgrade.com/industries/hospitality/"
                  }
                  label={"Learn more about hospitality"}
                  icon={data["whiterightArrowIcon"]}
                  rel={"noreferer"}
                  target={"_blank"}
                  blue
                  pill
                  whiteText
                  external
                />
              </IconBlock>
            </div>
          </div>
        </div>
      </PlatformRevenueContainer>

      <Content>
        <Content aboutContentImageBlock className={"content-image-block"}>
          <div className="left">
            <h3>Our leadership</h3>
            <p>
              Meet the diverse and experienced leadership team guiding us on our
              mission to become the global ancillary revenue powerhouse.
            </p>
            <Button
              destination={"/about/executive-lead-team"}
              label={"Plusgrade Executive Leadership Team"}
              icon={data["whiterightArrowIcon"]}
              orange
              pill
              whiteText
              aboutButton
            />
          </div>
          <div className="right">
            <GatsbyImage
              className={"aboutBg"}
              fixed={[
                data["mobileallHands"].childImageSharp.fixed,
                {
                  ...data.allHands.childImageSharp.fixed,
                  media: `(min-width: 991px)`,
                },
              ]}
              imgStyle={{ objectFit: "cover", objectPosition: "center" }}
            />
          </div>
        </Content>
      </Content>

      <CallToAction
        aboutUs
        title={lang.call_to_action.title}
        subtitle={lang.call_to_action.copy}
      >
        <Button
          destination={"/careers/"}
          label={"Current openings"}
          icon={data["rightArrowIcon"]}
          white
          pill
          blueText
        />
      </CallToAction>
    </Layout>
  );
};

export default IndexPage;
